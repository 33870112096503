<template>
  <div class="main-container">
    <div v-if="!registrationDisabled" class="register-container"> 
      <div v-if="registrationComplete" class="register-success-message">
        <p><b>Kayıt isteği başarılı!</b><br> Lütfen üyelik onayı için iletişime geçin. </p>
      </div>
      <div v-else>
        <div class="register-form">
          <h3>Kayıt istek formu</h3> 

          <form @submit.prevent="register">
            <div class="register-input-group">
              <label for="email">E-posta:</label>
              <input type="email" id="email" v-model="email" required class="register-input">
            </div>
            <div class="register-input-group">
              <label for="username">Kullanıcı Adı:</label>
              <input type="text" id="username" v-model="username" required class="register-input">
            </div>
            <div class="register-input-group">
              <label for="password">Şifre oluştur:</label>
              <input type="password" id="password" v-model="password" required class="register-input">
            </div>
            <div class="register-input-group">
              <label for="repeatPassword">Şifreyi Tekrarla:</label>
              <input type="password" id="repeatPassword" v-model="repeatPassword" required class="register-input">
            </div>
            <p style="color: #a7a7a7;">Kayıt olarak, <b>Kullanım Şartları</b> ve <b>Gizlilik Politikamızı</b> kabul etmiş oluyorsunuz.</p>
            <div v-if="errorMessage" class="register-error-message">
              <p>{{ errorMessage }}</p>
            </div>
            <div v-if="successMessage" class="register-success-message">
              <p>{{ successMessage }}</p>
            </div>
            <button type="submit" class="register-button">Kayıt Ol</button>
          </form>
        </div>
      </div>
    </div>
    <div v-if="registrationDisabled" class="registration-disabled-message">
      <p>Üzgünüz, şu anda kayıtlar kapalıdır. Daha sonra tekrar kontrol ediniz.</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      username: '',
      password: '',
      repeatPassword: '',
      errorMessage: null,
      successMessage: null,
      registrationComplete: false, 
      registrationDisabled: false,
    };
  },
  methods: {
    async register() {
      if (this.password !== this.repeatPassword) {
        this.errorMessage = 'Şifreler uyuşmuyor.';
        setTimeout(() => {
          this.errorMessage = null;
        }, 5000);
        return;
      }

      try {
        await axios.post('/auth/register', {
          e_mail: this.email,
          user_name: this.username,
          password: this.password
        });
        this.registrationComplete = true;
        this.successMessage = 'Kayıt başarılı!';
        setTimeout(() => {
          this.successMessage = null;
        }, 5000);
        this.$router.push('/login');
      } catch (error) {
        this.errorMessage = error.response?.data?.message || 'Bir hata oluştu. Lütfen tekrar deneyin.';
        setTimeout(() => {
          this.errorMessage = null;
        }, 5000);
      }
    }
  },
};
</script>

<style scoped>  
.register-form {  
  border-radius: 0.4em; 
}
.register-input-group {
  margin-bottom: 1em;
}
.register-input {
  width: 100%;
  padding: 0.8em;
  background-color: #000000;
  border: none;
  border-radius: 0.3em;
}
.register-button {
  padding: 0.8em 1.6em;
  background-color: rgba(36, 36, 36, 0.3);
  color: #a7a7a7;
  border: solid 1px #2c2c2c;
  border-radius: 0.3em;
  cursor: pointer;
  width: 100%;
}
</style> 