<template>
  <div class="datasetup-manager">
    <p>Product/service details of the advertisement to be created</p>
    <form @submit.prevent="handleSubmit">
      <div class="container">
        <div class="box">
          <p class="form-title">Product / Service info</p>
          <div>
            <label for="product_name">Product Name:</label>
            <input type="text" v-model="datasetup.product_name" required />
          </div>
          <div>
            <label for="description">Product Description:</label>
            <textarea v-model="datasetup.description"></textarea>
          </div>
        </div>
        <div class="box">
          <p class="form-title">Target User</p>
          <div>
            <label for="country">Country:</label>
            <input type="text" v-model="datasetup.country" />
          </div>
          <div>
            <label for="city">City:</label>
            <input type="text" v-model="datasetup.city" />
          </div>
          <div>
            <label for="min_age">Min Age:</label>
            <input type="number" v-model="datasetup.min_age" />
          </div>
          <div>
            <label for="max_age">Max Age:</label>
            <input type="number" v-model="datasetup.max_age" />
          </div>
          <div>
            <label for="gender">Gender:</label>
            <input type="text" v-model="datasetup.gender" />
          </div>
        </div>
        <div class="box">
          <p class="form-title">Purpose</p>
          <div>
            <label for="purpose">Details:</label>
            <textarea v-model="datasetup.purpose"></textarea>
          </div>
        </div>
      </div>
      <button type="submit">{{ isEditing ? 'Update Datasetup' : 'Create Datasetup' }}</button>
    </form>
    <div v-if="datasetups.length">
      <h2>Datasetups List</h2>
      <div v-for="datasetup in datasetups" :key="datasetup.datasetup_id">
        <div class="details">
          <p>Product Name: {{ datasetup.product_name }}</p>
          <p>Description: {{ datasetup.description }}</p>
          <p>Country: {{ datasetup.country }}</p>
          <p>City: {{ datasetup.city }}</p>
          <p>Min Age: {{ datasetup.min_age }}</p>
          <p>Max Age: {{ datasetup.max_age }}</p>
          <p>Gender: {{ datasetup.gender }}</p>
          <p>Purpose: {{ datasetup.purpose }}</p>
        </div>
        <div class="list-buttons">
          <button @click="editDatasetup(datasetup)">Edit</button>
          <button @click="confirmDelete(datasetup.datasetup_id)">Delete</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      datasetups: [],
      datasetup: {
        product_name: '',
        description: '',
        country: '',
        city: '',
        min_age: null,
        max_age: null,
        gender: '',
        purpose: ''
      },
      isEditing: false,
      currentDatasetupId: null,
    };
  },
  methods: {
    async fetchDatasetups() {
      try {
        const response = await axios.get('/ai/datasetups');
        this.datasetups = response.data.datasetups;
      } catch (error) {
        console.error('An error occurred while fetching datasetups:', error);
      }
    },
    async handleSubmit() {
      if (this.isEditing) {
        this.updateDatasetup();
      } else {
        this.createDatasetup();
      }
    },
    async createDatasetup() {
      try {
        const response = await axios.post('/ai/datasetups', this.datasetup);
        this.datasetups.push(response.data.datasetup);
        this.resetForm();
      } catch (error) {
        console.error('An error occurred while creating a datasetup:', error);
      }
    },
    async updateDatasetup() {
      try {
        const response = await axios.put(`/ai/datasetups/${this.currentDatasetupId}`, this.datasetup);
        const index = this.datasetups.findIndex(ds => ds.datasetup_id === this.currentDatasetupId);
        this.datasetups.splice(index, 1, response.data.datasetup);
        this.resetForm();
      } catch (error) {
        console.error('An error occurred while updating the datasetup:', error);
      }
    },
    async deleteDatasetup(datasetupId) {
      try {
        await axios.delete(`/ai/datasetups/${datasetupId}`);
        this.datasetups = this.datasetups.filter(ds => ds.datasetup_id !== datasetupId);
      } catch (error) {
        console.error('An error occurred while deleting the datasetup:', error);
      }
    },
    confirmDelete(datasetupId) {
      if (confirm('Are you sure you want to delete this datasetup?')) {
        this.deleteDatasetup(datasetupId);
      }
    },
    editDatasetup(datasetup) {
      this.datasetup = { ...datasetup };
      this.isEditing = true;
      this.currentDatasetupId = datasetup.datasetup_id;
    },
    resetForm() {
      this.datasetup = {
        product_name: '',
        description: '',
        country: '',
        city: '',
        min_age: null,
        max_age: null,
        gender: '',
        purpose: ''
      };
      this.isEditing = false;
      this.currentDatasetupId = null;
    },
  },
  mounted() {
    this.fetchDatasetups();
  },
};
</script>

<style scoped>
.datasetup-manager {
  padding: 20px;
}
.title {
  font-size: 24px;
  color: #28829e;
  margin-bottom: 10px;
}
form input, form textarea {
  width: 100%;
  padding: 4px;
  box-sizing: border-box;
}
form button {
  padding: 10px 15px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}
form button:hover {
  background-color: #45a049;
}
.datasetups-list {
  margin-top: 20px;
}
.datasetups-list h2 {
  margin-bottom: 10px;
}
.list-buttons {
  display: flex;
  gap: 10px;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.box {
  min-width: 33%;
}
@media (min-width: 768px) {
  .container {
    flex-direction: row;
  }
}
.form-title {
  font-size: 16px;
  font-weight: 600;
  color: #28829e;
}
textarea {
  min-height: 15em;
}
</style>
