<template>
  <div> 
    <div class="ai-home"> 
      <div class="container">
        <div class="box ads" @click="navigateTo('/administration/noah')">NOAH</div> 
        <div class="box report" @click="navigateTo('/administration/shop')">SHOP</div>
          <div class="box settings" @click="navigateTo('/administration/refika')">REFIKA</div> 
          <div class="box health" @click="navigateTo('/administration/ad')">AD</div>
          <div class="box nrs" @click="navigateTo('/administration/sys')">SYSTEM</div> 
          <div class="box beta" @click="navigateTo('/administration/test')">Test</div>
      </div>
      <div class="box log-out" @click="logout">Çıkış</div>
    </div>
  </div>
</template>

<script>
export default { 
  methods: {
    navigateTo(routePath) {
      this.$router.push(routePath);
    },
    logout() { 
      localStorage.removeItem('token'); 
      this.$router.push('/');
    }
  }
}
</script>

  
<style scoped>
.title {
  font-size: 24px;
  color: #28829e;
  margin-bottom: 10px;
}
.ai-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  min-height: 60vh;
}
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 2px; 
  padding: 10px;
  margin: 5em 0;
}
.box {
  min-width: 220px;
  min-height: 60px;
  background-color: #232323;
  color: #b1c3c9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;    
  text-align: center;
  font-weight: 300;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
  transition: background-color 0.3s ease;  
}
.box:hover {
  background-color: #191919;
  cursor: pointer; 
}
.ads {
  background-color: #007999; 
}
.ads:hover {
  background-color: #064f62; 
}
.report {
  background-color: #917e5c; 
}
.report:hover {
  background-color: #5d523d;
}
.settings {
  background-color: #127750;
}
.settings:hover {
  background-color: #085f3e; 
}
.health {
  background-color: #953342; 
}
.health:hover {
  background-color: #5f252e; 
}
.nrs {
  background-color: #023032;
}
.nrs:hover {
  background-color: #022121; 
}
.noah-logo {
  max-width: 15em;
  opacity: 0.6;
}
</style> 