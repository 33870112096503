<template>
  <div class="role-management" :class="{ darkMode }">
    <h3>Rol Yönetimi</h3>

    <h4>{{ isEdit ? "Rol Düzenle" : "Yeni Rol Ekle" }}</h4>
    <form @submit.prevent="isEdit ? updateRole() : createRole()">
      <input v-model="selectedRole.role_name" placeholder="Rol İsmi" required />
      <button type="submit">{{ isEdit ? "Güncelle" : "Rol Ekle" }}</button>
      <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
    </form>

    <div class="table-header">
      <h4>Tüm Roller</h4>
      <label>
        <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
      </label>
    </div>
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Rol İsmi</th>
          <th>Güncelle</th>
          <th v-if="showDeleteButtons">Sil</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="role in roles" :key="role.role_id">
          <td>{{ role.role_id }}</td>
          <td>{{ role.role_name }}</td>
          <td>
            <button @click="editRole(role)">Düzenle</button> 
          </td>
          <td v-if="showDeleteButtons"> 
            <button class="deletebutton" @click="confirmDelete(role.role_id)">Sil</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      roles: [],
      selectedRole: {
        role_name: '',
      },
      isEdit: false,
      darkMode: true,
      showDeleteButtons: false, 
    };
  },
  methods: {
    async fetchRoles() {
      const response = await axios.get('/admin/roles');
      this.roles = response.data;
    },
    async createRole() {
      try {
        await axios.post('/admin/roles', this.selectedRole);
        this.fetchRoles();
        this.resetForm();
      } catch (error) {
        console.error('Rol oluşturulurken hata:', error);
      }
    },
    editRole(role) {
      this.selectedRole = { ...role };
      this.isEdit = true;
    },
    async updateRole() {
      try {
        await axios.put(`/admin/roles/${this.selectedRole.role_id}`, this.selectedRole);
        this.fetchRoles();
        this.resetForm();
      } catch (error) {
        console.error('Rol güncellenirken hata:', error);
      }
    },
    async deleteRole(id) {
      try {
        await axios.delete(`/admin/roles/${id}`);
        this.fetchRoles();
      } catch (error) {
        console.error('Rol silinirken hata:', error);
      }
    },
    confirmDelete(id) {
      if (confirm('Bu rolü silmek istediğinize emin misiniz?')) {
        this.deleteRole(id);
      }
    },
    resetForm() {
      this.selectedRole = {
        role_name: '',
      };
      this.isEdit = false;
    },
    cancelEdit() {
      this.resetForm();
    },
  },
  mounted() {
    this.fetchRoles();
  },
};
</script>
 

<style scoped>
.role-management {
  padding: 20px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
form {
  margin-bottom: 2em;
}
</style>
