<template>
  <div id="app">  
    <MainHeader v-if="hasToken" />
    <div class="content">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" class="router-view"></component>
        </transition>
      </router-view>
    </div>
    <MainFooter />
  </div>
</template>

<script> 
import MainFooter from './components/MainFooter.vue'; 
import MainHeader from './components/MainHeader.vue';
import router from './router'; 

export default {
  name: 'App',
  components: { 
    MainFooter, 
    MainHeader
  },
  data() {
    return {
      hasToken: !!localStorage.getItem('token') 
    };
  },
  mounted() { 
    setInterval(() => {
      const token = localStorage.getItem('token');
      this.hasToken = !!token; 
      if (!token && router.currentRoute.path !== '/') {
        router.push('/');
      }
    }, 2000); 
  }
}
</script>

