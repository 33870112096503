<template>
  <div> 
    <div style="padding: 2em;"></div>
    <div class="sticky-bottom"><p>Copyright © Noah {{ currentYear }} Tüm hakları saklıdır.</p></div>
  </div>
</template>

<script>
export default {
  name: 'MainFooter',
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  },
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
    openLink(url) {
      window.open(url, '_blank');
    }
  }
}
</script>

<style scoped> 
.sticky-bottom {
  position: fixed;
  bottom: 0;
  width: 100%; 
  background-color: #000000;
  padding: 0.5rem 0; 
  padding-right: 32px; 
  text-align: center; 
}

.sticky-bottom p {  
  padding: 0;
  margin: 0; 
  font-size: 0.6rem;
} 

.menu-item {   
  font-size: 0.7rem;
  color: #cecece;
}

.menu-item:hover { 
  color: #ffffff;
  cursor: pointer;
}

@media (max-width: 768px) {
  .footer {
    grid-template-columns: repeat(2, 1fr);
  }

  .sticky-bottom p {  
    padding: 0;
    margin: 0;
    padding-right: 32px; 
    font-size: 0.5rem;
  }

  .icon-row {
    justify-content: space-between;
  }
}
</style>
