<template>
    <div class="education-management">
      <h3>Eğitim Yönetimi</h3>
  
      <h4>{{ isEdit ? "Eğitim Düzenle" : "Yeni Eğitim Ekle" }}</h4>
      <form @submit.prevent="isEdit ? updateEducation() : createEducation()">
        <input v-model="selectedEducation.name" placeholder="Eğitim Adı" required />
        <textarea v-model="selectedEducation.details" placeholder="Detaylar"></textarea>
        <input v-model="selectedEducation.serial_no" placeholder="Sıra Numarası" required />
        <label>
          <input type="checkbox" v-model="selectedEducation.is_active" /> Aktif
        </label>
        <label>
          <input type="checkbox" v-model="selectedEducation.is_loggedin" /> Giriş Gerektiriyor
        </label>
        <button type="submit">{{ isEdit ? "Güncelle" : "Eğitim Ekle" }}</button>
        <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
      </form>
  
      <div class="table-header">
        <h4>Tüm Eğitimler</h4>
        <label>
          <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
        </label>
      </div>
  
      <table class="education-table">
        <thead>
          <tr>
            <th>Sıra No</th>
            <th>Eğitim Adı</th>
            <th>Detaylar</th>
            <th>Aktif</th>
            <th>Giriş Gerektiriyor</th>
            <th>Düzenle</th>
            <th v-if="showDeleteButtons">Sil</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="education in educations" :key="education.education_id">
            <td>{{ education.serial_no }}</td>
            <td>{{ education.name }}</td>
            <td>{{ education.details }}</td>
            <td>{{ education.is_active ? "Evet" : "Hayır" }}</td>
            <td>{{ education.is_loggedin ? "Evet" : "Hayır" }}</td>
            <td><button @click="editEducation(education)">Düzenle</button></td>
            <td v-if="showDeleteButtons"><button class="deletebutton" @click="confirmDelete(education.education_id)">Sil</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        educations: [],
        selectedEducation: {
          education_id: null,
          name: '',
          details: '',
          serial_no: '', 
          is_active: true,
          is_loggedin: false,
        },
        isEdit: false,
        showDeleteButtons: false,
      };
    },
    methods: {
      async fetchEducations() {
        try {
          const response = await axios.get('/admin/educations');
          this.educations = response.data.educations;
        } catch (error) {
          console.error('Eğitimler alınırken hata oluştu:', error);
        }
      },
      async createEducation() {
        try {
          await axios.post('/admin/educations', {
            name: this.selectedEducation.name,
            details: this.selectedEducation.details,
            serial_no: this.selectedEducation.serial_no, 
            is_active: this.selectedEducation.is_active,
            is_loggedin: this.selectedEducation.is_loggedin,
          });
          this.fetchEducations();
          this.resetForm();
        } catch (error) {
          console.error('Eğitim eklenirken hata oluştu:', error);
        }
      },
      editEducation(education) {
        this.selectedEducation = { ...education };
        this.isEdit = true;
      },
      async updateEducation() {
        try {
          await axios.put(`/admin/educations/${this.selectedEducation.education_id}`, {
            name: this.selectedEducation.name,
            details: this.selectedEducation.details,
            serial_no: this.selectedEducation.serial_no, 
            is_active: this.selectedEducation.is_active,
            is_loggedin: this.selectedEducation.is_loggedin,
          });
          this.fetchEducations();
          this.resetForm();
        } catch (error) {
          console.error('Eğitim güncellenirken hata oluştu:', error);
        }
      },
      async deleteEducation(id) {
        try {
          await axios.delete(`/admin/educations/${id}`);
          this.fetchEducations();
        } catch (error) {
          console.error('Eğitim silinirken hata oluştu:', error);
        }
      },
      confirmDelete(id) {
        if (confirm('Bu eğitimi silmek istediğinizden emin misiniz?')) {
          this.deleteEducation(id);
        }
      },
      resetForm() {
        this.selectedEducation = {
          education_id: null,
          name: '',
          details: '',
          serial_no: '', 
          is_active: true,
          is_loggedin: false,
        };
        this.isEdit = false;
      },
      cancelEdit() {
        this.resetForm();
      },
    },
    mounted() {
      this.fetchEducations();
    },
  };
  </script>
  