<template>
  <div> 
    <div class="list-buttons">
      <button class="all-button"><img src="../../assets/icons/all.svg" class="button-icon"/>All</button> 
      <button class="auto-button"><img src="../../assets/icons/auto.svg" class="button-icon"/>AUTO MANAGEMENT</button>
      <button class="semi-button"><img src="../../assets/icons/semi.svg" class="button-icon"/>MANUAL</button>
      <button class="manage-button"><img src="../../assets/icons/warn.svg" class="button-icon"/>MANAGE</button>
    </div>

    <div v-if="loading">Loading...</div>

    <div v-else>
      <div v-for="campaign in campaigns" :key="campaign.id" class="campaign">
        <p class="campaign-name">{{ campaign.name }} <span style="font-size: 8px;">- {{ campaign.status }}</span>
          <img class="analyse-img" src="../../assets/icons/delete.svg"/> <button class="delete-camp" @click="confirmDeleteCampaign(campaign.id)">Delete</button>
        </p>
        <div class="adsets"> 
          <div v-for="adset in campaign.adsets" :key="adset.id" class="adset">
            <p class="adset-name">Set: {{ adset.name }} <span style="font-size: 8px;">- {{ adset.status }}</span></p> 
            
            <div class="ads"> 
              <div v-for="ad in adset.ads" :key="ad.id" class="ad">
                <div class="first-column">
                  <img class="action-img" v-if="ad.status === 'PAUSED'" src="../../assets/icons/paused.svg" alt="Paused Icon" @click="updateAdStatus(ad.id, 'ACTIVE')" />
                  <img class="action-img" v-if="ad.status === 'ACTIVE'" src="../../assets/icons/active.svg" alt="Active Icon" @click="updateAdStatus(ad.id, 'PAUSED')" />
                  <p class="ad-status">{{ ad.status }} </p> 
                  <p class="ad-name"> {{ ad.name }}</p> 
                </div>
                
                <div class="actions">
                  <img class="analyse-img" src="../../assets/icons/analyse.svg" @click="openModal('analyse', ad.id)"/><span style="color: #28829e; cursor: pointer;" @click="openModal('analyse', ad.id)">Analyse</span>
                  <img class="analyse-img" src="../../assets/icons/history.svg" @click="openModal('history', ad.id)"/><span style="color: #727272; cursor: pointer;" @click="openModal('history', ad.id)">History</span>
                  <img class="analyse-img" src="../../assets/icons/dashboard.svg" @click="openModal('dashboard', ad.id)"/><span style="color: #f2f2f2; cursor: pointer;" @click="openModal('dashboard', ad.id)">Dashboard</span>
                  <img class="analyse-img" src="../../assets/icons/delete.svg"/><span style="color: #ff0000; cursor: pointer;" @click="confirmDelete(ad.id)">Delete</span>
                  <img class="analyse-img" src="../../assets/icons/archive.svg"/><span style="color: #ff9700; cursor: pointer;">Archive</span>
                </div>
                
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
     

    <MetaAnalyseModal v-if="modalType === 'analyse'" @close="closeModal" :adId="selectedAdId"/>
    <MetaHistoryModal v-if="modalType === 'history'" @close="closeModal" :adId="selectedAdId"/>
    <MetaDashboardModal v-if="modalType === 'dashboard'" @close="closeModal" :adId="selectedAdId"/>
  </div>
</template>

<script>
import axios from 'axios';
import MetaAnalyseModal from './MetaAnalyseModal.vue';
import MetaHistoryModal from './MetaHistoryModal.vue';
import MetaDashboardModal from './MetaDashboardModal.vue';  

export default {
  components: {
    MetaAnalyseModal,
    MetaHistoryModal,
    MetaDashboardModal, 
  },
  data() {
    return {
      campaigns: [],
      loading: true,
      error: null,
      modalType: null,
      selectedAdId: null,
    };
  },
  methods: {
    async fetchMetaCampaigns() {
      try {
        console.log('Fetching Meta campaigns...');
        const response = await axios.get('/ai/ad/ads/metacampaigns');
        console.log('Campaigns fetched:', response.data);
        this.campaigns = response.data;
      } catch (error) {
        this.error = 'Error fetching campaigns';
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async updateAdStatus(adId, status) {
      try {
        await axios.post(`/ai/ad/ads/${adId}/status`, { status });
        this.fetchMetaCampaigns();
      } catch (error) {
        this.error = `Error updating ad status to ${status}`;
        console.error(error);
      }
    },
    openModal(type, adId) {
      this.modalType = type;
      this.selectedAdId = adId;
    },
    closeModal() {
      this.modalType = null;
      this.selectedAdId = null;
    },
    navigateTo(routePath) {
      this.$router.push(routePath);
    },
    navigateToAdDashboard(adId) {
      this.$router.push({ path: `/ad/ads/addashboard/${adId}` });
    },
    async deleteAd(adId) {
      try {
        console.log(`Deleting ad with ID: ${adId}`);
        await axios.delete(`/ai/ad/ads/${adId}`);
        this.fetchMetaCampaigns();
      } catch (error) {
        this.error = 'Error deleting ad';
        console.error(error);
      }
    },
    confirmDelete(adId) {
      if (confirm('Are you sure you want to delete this ad? This action cannot be undone.')) {
        this.deleteAd(adId);
      }
    },
    async deleteCampaign(campaignId) {
      try {
        console.log(`Deleting campaign with ID: ${campaignId}`);
        await axios.delete(`/ai/campaigns/${campaignId}`);
        this.fetchMetaCampaigns();
      } catch (error) {
        this.error = 'Error deleting campaign';
        console.error(error);
      }
    },
    confirmDeleteCampaign(campaignId) {
      if (confirm('Are you sure you want to delete this campaign? This action cannot be undone.')) {
        this.deleteCampaign(campaignId);
      }
    },
    createNew() { 
    }
  },
  mounted() {
    this.fetchMetaCampaigns();
  },
};
</script>
 
    <style scoped>
    .head-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0;
      width: 100%;
      gap: 5px;
    }
    .adhome-button {
      background-color: #917e5c;
    }
    .adhome-button:hover {
      background-color: #5d523d;
    }
    .home-button {
      background-color: #9b294f; 
    }
    .home-button:hover {
      background-color: #881f42;
    }
    .sync-button {
      background-color: #353535;
      font-size: 10px;
      font-weight: 300;
      color: white;
    }
    .sync-button:hover {
      background-color: #114d36;
    }
    .campaign {
      margin-bottom: 20px;
    }
    .campaign-name {
      color: #917e5c;
      background-color: black;
      padding: 10px 5px;
      font-weight: 800;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .adset-name {
      color: #007999;
      font-weight: 600;
      background-color: black;
      padding: 10px 25px; 
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .adset {
      margin-left: 0px;
    }
    .ad {
      background-color: black;
      padding: 10px 20px;
      margin: 5px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap; 
      gap: 5px;
    }
    .action-img {
      width: 20px;
      height: auto;
      cursor: pointer;
    }
    .action-img:hover {
      scale: 1.2;
    }
    .first-column { 
      display: flex;
      align-items: center; 
      gap: 5px;
    }
    .actions { 
      display: flex;
      align-items: center; 
      gap: 5px;
    }
    .analyse-img {
      width: 20px;
      height: auto;
      margin-left: 5em;
    }
    .ad-status{
      font-size: 0.8em;
    }
    .ad-name{
      margin-left: 3em;
    }
  
  
  
    .list-buttons {
    display: flex;
    align-items: center; 
    justify-content: flex-start;
    padding: 10px 0;
    gap: 5px;
  } 
  .create-button {
    background-color: #299b4f;
    min-width: 170px;
  }
  .create-button:hover {
    background-color: #20bc50;
  }
  .all-button {
    width: 5em;
  }
  .all-button img {
    max-height: 1em;
  }
  .auto-button {
    background-color: #299b4f;
    min-width: 200px;
  }
  .auto-button:hover {
    background-color: #20bc50;
  }
  .semi-button {
    background-color: #997929;
    min-width: 170px;
  }
  .semi-button:hover {
    background-color: #c19430;
  }
  .manage-button {
    background-color: #2e2e2e;
    opacity: 0.5;
    min-width: 170px;
  }
  .manage-button:hover {
    background-color: #bc2222;
    opacity: 1;
  } 
  .delete-camp {
  background-color: #00789900;
  font-weight: 300;
  padding: 0;
  margin: 0;
  color: red;
  }
</style>
    