<template>
  <div class="category-management">
    <h3>Ana Kategoriler</h3>

    <h4>{{ isEdit ? "Kategori Düzenle" : "Yeni Kategori Ekle" }}</h4>
    <form @submit.prevent="isEdit ? updateCategory() : createCategory()">
      <input v-model="selectedCategory.name" placeholder="Kategori Adı" required />
      <input v-model="selectedCategory.description" placeholder="Açıklama" />
      <input v-model.number="selectedCategory.order_no" placeholder="Sıra Numarası" type="number" required />
      <input v-model="selectedCategory.short_link" placeholder="Kısa Link" /> 
      <button type="submit">{{ isEdit ? "Güncelle" : "Kategori Ekle" }}</button>
      <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
    </form>

    <div class="table-header">
      <h4>Tüm Kategoriler</h4>
      <label>
        <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
      </label>
    </div>

    <table class="category-table">
      <thead>
        <tr>
          <th>Kategori Adı</th>
          <th>Açıklama</th>
          <th>Sıra Numarası</th>
          <th>Kısa Link</th> 
          <th>Düzenle</th>
          <th v-if="showDeleteButtons">Sil</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="category in categories" :key="category.id">
          <td>{{ category.name }}</td>
          <td>{{ category.description }}</td>
          <td>{{ category.order_no }}</td>
          <td>{{ category.short_link }}</td> 
          <td><button @click="editCategory(category)">Düzenle</button></td>
          <td v-if="showDeleteButtons"><button class="deletebutton" @click="confirmDelete(category.id)">Sil</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default { 
  data() {
    return {
      categories: [],
      selectedCategory: {
        id: null,
        name: '',
        description: '',
        order_no: 0,
        short_link: '', 
      },
      isEdit: false,
      showDeleteButtons: false,
    };
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await axios.get('/promo/main-categories');
        this.categories = response.data.mainCategories;
      } catch (error) {
        console.error('Kategoriler alınırken hata oluştu:', error); 
      }
    },
    async createCategory() {
      try {
        await axios.post('/promo/main-category', {
          name: this.selectedCategory.name,
          description: this.selectedCategory.description,
          order_no: this.selectedCategory.order_no,
          short_link: this.selectedCategory.short_link, 
        });
        this.fetchCategories();
        this.resetForm(); 
      } catch (error) {
        console.error('Kategori eklenirken hata oluştu:', error); 
      }
    },
    editCategory(category) {
      this.selectedCategory = { ...category };
      this.isEdit = true;
    },
    async updateCategory() {
      try {
        await axios.put(`/promo/main-category/${this.selectedCategory.id}`, {
          name: this.selectedCategory.name,
          description: this.selectedCategory.description,
          order_no: this.selectedCategory.order_no,
          short_link: this.selectedCategory.short_link, 
        });
        this.fetchCategories();
        this.resetForm(); 
      } catch (error) {
        console.error('Kategori güncellenirken hata oluştu:', error); 
      }
    },
    async deleteCategory(id) {
      try {
        await axios.delete(`/promo/main-category/${id}`);
        this.fetchCategories(); 
      } catch (error) {
        console.error('Kategori silinirken hata oluştu:', error); 
      }
    },
    confirmDelete(id) {
      if (confirm('Bu kategoriyi silmek istediğinizden emin misiniz?')) {
        this.deleteCategory(id);
      }
    },
    resetForm() {
      this.selectedCategory = {
        id: null,
        name: '',
        description: '',
        order_no: 0,
        short_link: '', 
      };
      this.isEdit = false;
    },
    cancelEdit() {
      this.resetForm();
    },
  },
  mounted() {
    this.fetchCategories();
  },
};
</script>
