<template>
  <div class="messagetype-management">
    <h3>Mesaj Tipleri Yönetimi</h3>

    <h4>{{ isEdit ? "Mesaj Tipini Düzenle" : "Yeni Mesaj Tipi Ekle" }}</h4>
    <form @submit.prevent="isEdit ? updateMessageType() : createMessageType()">
      <input v-model="selectedMessageType.name" placeholder="Mesaj Tipi Adı" required />
      <textarea v-model="selectedMessageType.details" placeholder="Detaylar"></textarea>
      <label>
        <input type="checkbox" v-model="selectedMessageType.is_active" />
        Aktif Mi?
      </label>
      <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
      <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
    </form>

    <h4>Tüm Mesaj Tipleri</h4>
    <table>
      <thead>
        <tr>
          <th>Adı</th>
          <th>Detaylar</th>
          <th>Aktif Mi?</th>
          <th>İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="msgType in messageTypes" :key="msgType.message_type_id">
          <td>{{ msgType.name }}</td>
          <td>{{ msgType.details }}</td>
          <td>{{ msgType.is_active ? 'Evet' : 'Hayır' }}</td>
          <td>
            <button @click="editMessageType(msgType)">Düzenle</button>
            <button @click="confirmDelete(msgType.message_type_id)">Sil</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'MessageTypeManager',
  data() {
    return {
      messageTypes: [],
      selectedMessageType: {
        message_type_id: null,
        name: '',
        details: '',
        is_active: false,
      },
      isEdit: false,
    };
  },
  methods: {
    async fetchMessageTypes() {
      try {
        const response = await axios.get('/admin/messagetypes');
        this.messageTypes = response.data;
      } catch (error) {
        console.error('Mesaj tipleri alınırken hata oluştu:', error);
      }
    },
    async createMessageType() {
      try {
        await axios.post('/admin/messagetypes', this.selectedMessageType);
        this.fetchMessageTypes();
        this.resetForm();
      } catch (error) {
        console.error('Mesaj tipi eklenirken hata oluştu:', error);
      }
    },
    editMessageType(msgType) {
      this.selectedMessageType = { ...msgType };
      this.isEdit = true;
    },
    async updateMessageType() {
      try {
        await axios.put(`/admin/messagetypes/${this.selectedMessageType.message_type_id}`, this.selectedMessageType);
        this.fetchMessageTypes();
        this.resetForm();
      } catch (error) {
        console.error('Mesaj tipi güncellenirken hata oluştu:', error);
      }
    },
    async deleteMessageType(message_type_id) {
      try {
        await axios.delete(`/admin/messagetypes/${message_type_id}`);
        this.fetchMessageTypes();
      } catch (error) {
        console.error('Mesaj tipi silinirken hata oluştu:', error);
      }
    },
    confirmDelete(message_type_id) {
      if (confirm('Bu mesaj tipini silmek istediğinize emin misiniz?')) {
        this.deleteMessageType(message_type_id);
      }
    },
    resetForm() {
      this.selectedMessageType = {
        message_type_id: null,
        name: '',
        details: '',
        is_active: false,
      };
      this.isEdit = false;
    },
    cancelEdit() {
      this.resetForm();
    },
  },
  mounted() {
    this.fetchMessageTypes();
  },
};
</script>
 