import axios from 'axios';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/global-dark.css';

// Axios base URL ayarı
axios.defaults.baseURL = 'https://noahpmu.com/api';
 
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);  

const app = createApp(App);

app.mixin({
  methods: { 
    formatDateTime(dateTime) {
      if (!dateTime) return '';

      const date = new Date(dateTime);
       
      const formattedDate = new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit', 
        hour12: false,
        timeZone: 'Europe/Istanbul' 
      }).format(date); 
      return `${formattedDate.replace(',', '')}`;
    }
  }
}); 

app.config.devtools = false;

app.use(router);

app.mount('#app');
