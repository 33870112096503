<template>
    <div class="minor-category-management">
      <h3>Gösterilen Ürünler</h3>
  
      <h4>{{ isEdit ? "Ürün Düzenle" : "Yeni Ürün Ekle" }}</h4>
      <form @submit.prevent="isEdit ? updateMinorCategory() : createMinorCategory()">
        <input v-model="selectedMinorCategory.name" placeholder="Ürün Adı" required />
        <textarea v-model="selectedMinorCategory.description" placeholder="Açıklama" rows="5" cols="50" style="resize: both;"></textarea>

        <select v-model="selectedMinorCategory.main_category_id" required>
          <option disabled value="">Ana Kategoriyi Seçin</option>
          <option v-for="category in categories" :key="category.id" :value="category.id">
            {{ category.name }}
          </option>
        </select>
        <select v-model="selectedMinorCategory.sub_category_id" required>
          <option disabled value="">Alt Kategoriyi Seçin</option>
          <option v-for="subcategory in subcategories" :key="subcategory.id" :value="subcategory.id">
            {{ subcategory.name }}
          </option>
        </select>
  
        <div>
          <label for="main-image">Ana Görsel:</label>
          <input class="upload-button" type="file" id="main-image" @change="handleFileUpload('main_image', $event)" />
          <img v-if="preview.main_image" :src="preview.main_image" alt="Ana Görsel" class="image-preview" />
        </div>
        
        <div>
            <label for="main-video">Ana Video:</label>
            <input class="upload-button" type="file" id="main-video" @change="handleFileUpload('main_video', $event)" />
        </div>
        
        <div>
          <label for="detail-image">Detay Görsel:</label>
          <input class="upload-button" type="file" id="detail-image" @change="handleFileUpload('detail_image', $event)" />
          <img v-if="preview.detail_image" :src="preview.detail_image" alt="Detay Görsel" class="image-preview" />
        </div>
        <div>
          <label for="background-image">Arka Plan Görsel:</label>
          <input class="upload-button" type="file" id="background-image" @change="handleFileUpload('background_image', $event)" />
          <img v-if="preview.background_image" :src="preview.background_image" alt="Arka Plan Görsel" class="image-preview" />
        </div>
  
        <button type="submit">{{ isEdit ? "Güncelle" : "Ürün Ekle" }}</button>
        <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
      </form>
  
      <div class="table-header">
        <h4>Tüm Ürünler</h4>
        <label>
          <input type="checkbox" v-model="showDeleteButtons" /> Silme Butonlarını Göster
        </label>
      </div>
  
      <table class="minor-category-table">
        <thead>
          <tr>
            <th>Ürün Adı</th>
            <th>Açıklama</th>
            <th>Ana Kategori</th>
            <th>Alt Kategori</th>
            <th>Ana Görsel</th>
            <th>Detay Görsel</th>
            <th>Arka Plan Görsel</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="minorCategory in minorCategories" :key="minorCategory.id">
            <td>{{ minorCategory.name }}</td>
            <td>{{ minorCategory.description }}</td>
            <td>{{ getCategoryName(minorCategory.main_category_id) }}</td>
            <td>{{ getSubCategoryName(minorCategory.sub_category_id) }}</td>
            <td>
              <img 
                v-if="minorCategory.main_image" 
                :src="minorCategory.main_image" 
                alt="Ana Görsel" 
                class="image-preview"
              />
              <span v-else>Yok</span>
            </td>
            <td>
              <img 
                v-if="minorCategory.detail_image" 
                :src="minorCategory.detail_image" 
                alt="Detay Görsel" 
                class="image-preview"
              />
              <span v-else>Yok</span>
            </td>
            <td>
              <img 
                v-if="minorCategory.background_image" 
                :src="minorCategory.background_image" 
                alt="Arka Plan Görsel" 
                class="image-preview"
              />
              <span v-else>Yok</span>
            </td>
            <td>
              <button @click="editMinorCategory(minorCategory)">Düzenle</button>
              <button v-if="showDeleteButtons" class="deletebutton" @click="confirmDelete(minorCategory.id)">Sil</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        minorCategories: [],
        categories: [],
        subcategories: [],
        selectedMinorCategory: {
          id: null,
          name: '',
          description: '',
          main_category_id: '',
          sub_category_id: '',
          main_image: null,
          main_video: null,
          detail_image: null,
          background_image: null,
        },
        preview: {
          main_image: null,
          detail_image: null,
          background_image: null,
        },
        isEdit: false,
        showDeleteButtons: false,
      };
    },
    methods: {
      getCategoryName(id) {
        const category = this.categories.find(cat => cat.id === id);
        return category ? category.name : 'Bilinmiyor';
      },
      getSubCategoryName(id) {
        const subcategory = this.subcategories.find(sub => sub.id === id);
        return subcategory ? subcategory.name : 'Bilinmiyor';
      },
      async fetchMinorCategories() {
        try {
          const response = await axios.get('/promo/minor-categories');
          this.minorCategories = response.data.minorCategories;
        } catch (error) {
          console.error('Ürünler alınırken hata oluştu:', error);
        }
      },
      async fetchCategories() {
        try {
          const response = await axios.get('/promo/main-categories');
          this.categories = response.data.mainCategories;
        } catch (error) {
          console.error('Ana kategoriler alınırken hata oluştu:', error);
        }
      },
      async fetchSubcategories() {
        try {
          const response = await axios.get('/promo/sub-categories');
          this.subcategories = response.data.subCategories;
        } catch (error) {
          console.error('Alt kategoriler alınırken hata oluştu:', error);
        }
      },
      handleFileUpload(type, event) {
          const file = event.target.files[0];
          const reader = new FileReader();
          reader.onload = e => {
              if (type === 'main_image') {
                  this.preview.main_image = e.target.result;
                  this.selectedMinorCategory.main_image = file;
              } else if (type === 'main_video') {  
                  this.selectedMinorCategory.main_video = file;
              } else if (type === 'detail_image') {
                  this.preview.detail_image = e.target.result;
                  this.selectedMinorCategory.detail_image = file;
              } else if (type === 'background_image') {
                  this.preview.background_image = e.target.result;
                  this.selectedMinorCategory.background_image = file;
              }
          };
          reader.readAsDataURL(file);
      },
      async createMinorCategory() {
          try {
              const formData = new FormData();
              formData.append('name', this.selectedMinorCategory.name);
              formData.append('description', this.selectedMinorCategory.description);
              formData.append('main_category_id', this.selectedMinorCategory.main_category_id);
              formData.append('sub_category_id', this.selectedMinorCategory.sub_category_id);
      
              if (this.selectedMinorCategory.main_image) {
                  formData.append('main_image', this.selectedMinorCategory.main_image);
              }
              if (this.selectedMinorCategory.main_video) {  
                  formData.append('main_video', this.selectedMinorCategory.main_video);
              }
              if (this.selectedMinorCategory.detail_image) {
                  formData.append('detail_image', this.selectedMinorCategory.detail_image);
              }
              if (this.selectedMinorCategory.background_image) {
                  formData.append('background_image', this.selectedMinorCategory.background_image);
              }
      
              await axios.post('/promo/minor-category', formData, {
                  headers: { 'Content-Type': 'multipart/form-data' },
              });
      
              this.fetchMinorCategories();
              this.resetForm();
          } catch (error) {
              console.error('Ürün eklenirken hata oluştu:', error);
          }
      },
      async updateMinorCategory() {
          try {
              const formData = new FormData();
              formData.append('name', this.selectedMinorCategory.name);
              formData.append('description', this.selectedMinorCategory.description);
              formData.append('main_category_id', this.selectedMinorCategory.main_category_id);
              formData.append('sub_category_id', this.selectedMinorCategory.sub_category_id);
      
              if (this.selectedMinorCategory.main_image) {
                  formData.append('main_image', this.selectedMinorCategory.main_image);
              }
              if (this.selectedMinorCategory.main_video) { 
                  formData.append('main_video', this.selectedMinorCategory.main_video);
              }
              if (this.selectedMinorCategory.detail_image) {
                  formData.append('detail_image', this.selectedMinorCategory.detail_image);
              }
              if (this.selectedMinorCategory.background_image) {
                  formData.append('background_image', this.selectedMinorCategory.background_image);
              }
      
              await axios.put(`/promo/minor-category/${this.selectedMinorCategory.id}`, formData, {
                  headers: { 'Content-Type': 'multipart/form-data' },
              });
      
              this.fetchMinorCategories();
              this.resetForm();
          } catch (error) {
              console.error('Ürün güncellenirken hata oluştu:', error);
          }
      },
      editMinorCategory(minorCategory) {
        this.selectedMinorCategory = {
          ...minorCategory,
          main_image: null,
          detail_image: null,
          background_image: null,
        };
        this.preview = {
          main_image: minorCategory.main_image,
          detail_image: minorCategory.detail_image,
          background_image: minorCategory.background_image,
        };
        this.isEdit = true;
      },
      async deleteMinorCategory(id) {
        try {
          await axios.delete(`/promo/minor-category/${id}`);
          this.fetchMinorCategories();
        } catch (error) {
          console.error('Ürün silinirken hata oluştu:', error);
        }
      },
      confirmDelete(id) {
        if (confirm('Bu Ürün silmek istediğinizden emin misiniz?')) {
          this.deleteMinorCategory(id);
        }
      },
      resetForm() {
        this.selectedMinorCategory = {
          id: null,
          name: '',
          description: '',
          main_category_id: '',
          sub_category_id: '',
          main_image: null,
          detail_image: null,
          background_image: null,
        };
        this.preview = {
          main_image: null,
          detail_image: null,
          background_image: null,
        };
        this.isEdit = false;
      },
      cancelEdit() {
        this.resetForm();
      },
    },
    mounted() {
      this.fetchCategories();
      this.fetchSubcategories();
      this.fetchMinorCategories();
    },
  };
  </script> 